<template>
  <div class="container">
    <div class="subject">クライアント一覧</div>
    <div class="new-create">
      <button
        class="new-create-button"
        v-on:click="changeRegisterClientScreen()"
      >
        新規作成+
      </button>
    </div>
    <div>
      <table class="table">
        <thead>
          <tr class="topic">
            <th>クライアントID</th>
            <th>クライアント名</th>
            <th>契約期間</th>
            <th>契約プラン</th>
            <th>使用本数（使用中データ容量）</th>
            <th colspan="6"></th>
          </tr>
        </thead>
        <tbody v-for="(client, index) in clientInfo" :key="index">
          <tr height="50">
            <td>{{ client.client_id }}</td>
            <td>
              <a
                :href="
                //'https://client-3e3c0.web.app/?rrdwa=' +//テスト用コード
                'https://client.short-video.jp/?rrdwa=' +//本番用コード
                  client.client_id +
                  '&sec=' +
                  client.seacretId
                "
                >{{ client.client_name }}</a
              >
            </td>
            <td>
              <div
                v-if="checkPeriod(client.contract_finished_day)"
                class="close-box"
              >
                ×
              </div>
              <div class="period">
                {{ client.contract_start_day }}〜{{
                  client.contract_finished_day
                }}
              </div>
            </td>
            <td>Limit{{ client.contract_plan }}DBプラン</td>
            <td>
              {{ client.use_number }}本 使用中 （{{
                Math.floor(client.used_db)
              }}DB/{{ client.contract_plan }}DB使用中）
            </td>
            <td width="20" class="rewrite-text">
              <button
                class="rewrite-button"
                type="submit"
                v-on:click="changeRewriteScreen(index)"
              >
                編集
              </button>
            </td>
            <td width="20">
              <button
                class="delete-button"
                type="submit"
                v-on:click="deleteModal(index, client.client_id)"
              >
                削除
              </button>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="deleteModal" class="modal">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-content">
            <p class="delete-text">削除後復元出来ません。</p>
            <p class="delete-text">削除しますか？</p>
            <div class="btn-modal">
              <div>
                <button
                  class="modal-cancel-button modalClose"
                  type="submit"
                  v-on:click="hide()"
                >
                  削除
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-auth.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script>
const tus = require("tus-js-client");
const Vimeo = require("vimeo").Vimeo;

var VIMEO_CLIENT_ID = process.env.VUE_APP_VIMEO_CLIENT_ID;
var VIMEO_CLIENT_SECRET = process.env.VUE_APP_VIMEO_CLIENT_SECRET;
var VIMEO_ACCESS_TOKEN = process.env.VUE_APP_VIMEO_ACCESS_TOKEN;

const client = new Vimeo(
  VIMEO_CLIENT_ID,
  VIMEO_CLIENT_SECRET,
  VIMEO_ACCESS_TOKEN
);
export default {
  name: "ClientHome",
  data: function () {
    return {
      clientId: "",
      temporaryData: [],
      temporaryManagementData: [],
    };
  },
  mounted() {
    console.log("ホーム画面がマウントされました");
    this.$store.commit("resetProject");
    document.body.className = "login";
    document.querySelector("body").style.background = "#F8F8F8";
    this.$root.headerHide = true;
    this.$root.footerHide = true;
  },
  beforeDestroy() {
    document.body.className = "";
    this.$root.headerHide = false;
    this.$root.footerHide = false;
  },
  computed: {
    clientInfo: {
      get() {
        return this.$store.state.clients;
      },
      set() {
        return this.$store.state.clients;
      },
    },
  },
  methods: {
    emitEvent() {
      this.$emit("child-emit");
    },
    checkPeriod(period) {
      var finishedDate = new Date(period.toString().replace(/\//g, "-"));

      var today = new Date();

      if (finishedDate > today) {
        return false;
      } else {
        return true;
      }
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得している間の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    waitLoading: function () {
      document.getElementById("loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得した後の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    downloaded: function () {
      document.getElementById("loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
    ////////////////////////////////////
    //説明：削除ボタンを押した際、モーダル表示
    //
    //
    //
    //引数:index:選択したクライアントのインデックス番号
    /////////////////////////////////////////////////
    deleteModal(index, clientId) {
      this.clientId = clientId;
      this.$store.commit("selectedClientSetter", index);
      //削除モーダルを表示させる
      document.getElementById("deleteModal").style.display = "block";
    },
    ////////////////////////////////////
    //説明：クライアントを削除し、モーダルを非表示に
    //
    //
    //
    //引数:なし
    /////////////////////////////////////////////////
    hide: function () {
      //クライアントを削除する
      this.deleteClientInfo();

      //削除モーダルを非表示にする
      document.getElementById("deleteModal").style.display = "none";
    },
    /////////////////////////////////////
    //////////////
    ////////説明
    ////////クライアント編集画面に遷移する処理
    ///
    ///引数：index:選択したクライアントのインデントナンバー
    ///////////////////////
    ////////////////////////////////////////////////////
    changeRewriteScreen(index) {
      this.$store.commit("selectedClientSetter", index);
      this.$router.push("/clientRewrite");
    },
    /////////////////////////////////////
    //////////////
    ////////説明
    ////////クライアント新規作成画面に遷移する処理
    ///
    ///引数：なし
    ///////////////////////
    ////////////////////////////////////////////////////
    changeRegisterClientScreen() {
      this.$router.push("/clientRegister");
    },
    /////////////////////////////////////
    //////////////
    ////////説明
    ////////プロジェクト情報を取得し削除する
    ///
    ///引数：なし
    ///////////////////////
    ////////////////////////////////////////////////////
    getProjectInfo: async function () {
      this.$store.commit("resetProject");
      this.temporaryData = [];
      var count = 0;

      //クライアントIDを取得
      var clientId = this.clientId;
      var projectInfo = this;
      var store = this.$store;
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var listRef = storageRef.child("/clientList/" + clientId);
      var list = await listRef.listAll();
      var imgList = [];
      Promise.all(
        list.items.map(async (element) => {
          await imgList.push(element);
        })
      );
      for (var i = 0; i < imgList.length; i++) {
        imgList[i]
          .getDownloadURL()
          .then((url) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.onload = function (event) {
              var blob = xhr.response;
              //プロジェクト情報をいれる
              projectInfo.temporaryData.push(JSON.parse(blob));
              store.commit("setProjectInfo", JSON.parse(blob));
              count += 1;
              if (count == imgList.length) {
                //プロジェクト情報を削除
                projectInfo.deleteProjectInfo();

                //vimeoから動画を削除する
                projectInfo.deleteVimeo();
              }
            };
            xhr.send();
          })
          .then((response) => {
            // 返ってきたresponseのdataプロパティにjsonファイルの中身が格納されている
            const data = response;
          });
      }
    },
    /////////////////////////////////////
    //////////////
    ////////説明
    ////////クライアントを削除する処理
    ///
    ///引数：なし
    ///////////////////////
    ////////////////////////////////////////////////////
    deleteClientInfo() {
      this.getProjectJsonFile();
    },
    /////////////////////////////////////
    //////////////
    ////////説明
    ////////もろもろのフォルダを消すためにプロジェクト情報を一旦すべて取得する
    ///
    ///引数：なし
    ///////////////////////
    ////////////////////////////////////////////////////
    getProjectJsonFile: function () {
      this.waitLoading();
      var store = this.$store;
      var instance = this;
      var clientId =
        this.$store.state.clients[this.$store.state.selectedClient].client_id;

      var seacretId =
        this.$store.state.clients[this.$store.state.selectedClient].seacretId;

      var deleteClientMail =
        this.$store.state.clients[this.$store.state.selectedClient].mail;

      var deleteClientPass =
        this.$store.state.clients[this.$store.state.selectedClient].password;

      //このパスからデータを取得する
      firebase
        .storage()
        .ref()
        .child("/project/" + clientId + "/" + seacretId + "/project.json")
        .getDownloadURL()
        .then((url) => {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url);
          xhr.onload = async function (event) {
            var blob = xhr.response;
            var projectList = JSON.parse(blob);
            store.commit("setAllProject", projectList);

            console.log("削除処理を開始します");
            //このパスからデータを取得する
            await firebase
              .storage()
              .ref()
              .child("/account/" + clientId + "/" + "managerList.json")
              .getDownloadURL()
              .then(async (url) => {
                var xhr = new XMLHttpRequest();
                await xhr.open("GET", url);
                xhr.onload = async function (event) {
                  var blob = xhr.response;
                  var managerList = JSON.parse(blob);
                  var clientInfo = store.state.clients;
                  for (var i = 0; i < clientInfo.length; i++) {
                    if (clientInfo[i].client_id == clientId) {
                      clientInfo.splice(i, 1);
                    }
                  }
                  await instance.deleteVimeo();
                  await instance.deleteImgFolder(clientId);
                  await instance.deleteProjectJsonFile(clientId, seacretId);
                  await instance.deleteUserFolder(clientId);
                  await instance.deleteProjectDB(clientId);
                  await instance.deletePublishedFolder(clientId);
                  await instance.deleteServiceLogo(clientId);
                  await instance.deleteClientDB(clientId);
                  await instance.deleteAccountFloder(clientId);
                  await instance.deleteClientJsonFile(clientId, seacretId);
                  await instance.deleteAdminClientListKey(clientId);
                  await instance.deleteAuthenticationUser(managerList);
                  await instance.deleteBelongClient(
                    clientId,
                    deleteClientMail,
                    deleteClientPass
                  );
                  instance.downloaded();
                };
                xhr.send();
              });
          };
          xhr.send();
        });
    },
    getManagerListJson: async function (clientId) {
      //このパスからデータを取得する
      await firebase
        .storage()
        .ref()
        .child("/account/" + clientId + "/" + "managerList.json")
        .getDownloadURL()
        .then(async (url) => {
          var xhr = new XMLHttpRequest();
          await xhr.open("GET", url);
          xhr.onload = await function (event) {
            var blob = xhr.response;
            var managerList = JSON.parse(blob);
            console.log(managerList);
            console.log(managerList.length);
          };
          xhr.send();
        });
    },
    /////////////////////////////////////
    //////////////
    ////////説明
    ////////プロジェクトJSONファイルを削除する
    ///
    ///引数：なし
    ///////////////////////
    ////////////////////////////////////////////////////
    deleteProjectJsonFile: async function (clientId, seacretId) {
      console.log("---------deleteAllProject----------");
      //全プロジェクトが保存されているパス
      var allProjectJson = firebase
        .storage()
        .ref()
        .child("/project/" + clientId + "/" + seacretId);

      var list = await allProjectJson.listAll();
      await Promise.all(
        list.items.map(async (element) => {
          await element.delete();
          console.log("deleteしました");
        })
      );
      console.log("---------deleteAllProjectの処理が終了しました----------");
    },
    /////////////////////////////////////
    //////////////
    ////////説明
    ////////画像フォルダを削除する
    ///
    ///引数：なし
    ///////////////////////
    ////////////////////////////////////////////////////
    deleteImgFolder: async function (clientId) {
      console.log("---------deleteImgFolder------------");
      //全プロジェクトを取得する
      var allProject = this.$store.state.allProject[0];
      for (var i = 0; i < allProject.length; i++) {
        var imgPass = firebase
          .storage()
          .ref()
          .child("/img/" + clientId + "/" + allProject[i].project_id);

        var list = await imgPass.listAll();
        await Promise.all(
          list.items.map(async (element) => {
            await element.delete();
            console.log("deleteしました");
          })
        );
      }
      console.log("---------deleteImgFolderの処理が終了しました------------");
    },
    /////////////////////////////////////
    //////////////
    ////////説明
    ////////ユーザーフォルダを削除する
    ///
    ///引数：なし
    ///////////////////////
    ////////////////////////////////////////////////////
    deleteUserFolder: async function (clientId) {
      console.log("---------deleteUserFolder------------");
      //全プロジェクトを取得する
      var allProject = this.$store.state.allProject[0];
      for (var i = 0; i < allProject.length; i++) {
        var userPass = firebase
          .storage()
          .ref()
          .child("/user/" + clientId + "/" + allProject[i].project_id);

        var list = await userPass.listAll();
        await Promise.all(
          list.items.map(async (element) => {
            await element.delete();
            console.log("deleteしました");
          })
        );
      }
      console.log("---------deleteUserFolderの処理が終了しました------------");
    },
    deleteProjectDB: async function (clientId) {
      console.log("---------deleteProjectDB------------");
      //全プロジェクトを取得する
      var allProject = this.$store.state.allProject[0];
      for (var i = 0; i < allProject.length; i++) {
        var projectDBPass = firebase
          .storage()
          .ref()
          .child("/db/" + clientId + "/");

        var list = await projectDBPass.listAll();
        await Promise.all(
          list.items.map(async (element) => {
            await element.delete();
            console.log("deleteしました");
          })
        );
      }
      console.log("---------deleteProjectDBの処理が終了しました------------");
    },
    deleteClientDB: async function (clientId) {
      console.log("---------deleteClientDB------------");
      var clientDBPass = firebase
        .storage()
        .ref()
        .child("/db/" + clientId + ".json");
      // すべて削除
      await clientDBPass.delete();
      console.log("---------deleteClientDBの処理が終了しました------------");
    },
    deleteAccountFloder: async function (clientId) {
      console.log("---------deleteAccountFloder------------");
      var accountPass = firebase
        .storage()
        .ref()
        .child("/account/" + clientId + "/");

      var list = await accountPass.listAll();
      await Promise.all(
        list.items.map(async (element) => {
          await element.delete();
          console.log("deleteしました");
        })
      );
      console.log(
        "---------deleteAccountFloderの処理が終了しました------------"
      );
    },
    deleteClientJsonFile: async function (clientId, seacretId) {
      console.log("---------deleteClientJsonFile------------");
      var clientJsonFile = firebase
        .storage()
        .ref()
        .child("/client/" + clientId + "/" + seacretId + "/");

      var list = await clientJsonFile.listAll();
      await Promise.all(
        list.items.map(async (element) => {
          await element.delete();
          console.log("deleteしました");
        })
      );
      console.log(
        "---------deleteClientJsonFileの処理が終了しました------------"
      );
    },
    deleteAdminClientListKey: async function (clientId) {
      console.log("---------deleteAdminClientListKey------------");
      //このクライアントIDをもとにクライアント情報を取得する
      var clientIdList = this.$store.state.clientIdList;
      for (var i = 0; i < clientIdList.length; i++) {
        if (clientIdList[i].id == clientId) {
          clientIdList.splice(i, 1);
          break;
        }
      }
      //このパスにjsonファイルを格納する
      const ref = firebase
        .storage()
        .ref()
        .child("/adminClientList/clientIdList.json");
      const jsonString = JSON.stringify(clientIdList);
      const blob = new Blob([jsonString], { type: "application/json" });
      await ref.put(blob);
      console.log(
        "---------deleteAdminClientListKeyの処理が終了しました------------"
      );
    },
    deleteBelongClient: async function (
      clientId,
      deleteClientMail,
      deleteClientPass
    ) {
      console.log("---------deleteBelongClient------------");
      console.log(clientId);
      console.log(deleteClientMail);
      console.log(deleteClientPass);
      var instance = this;
      //このパスにjsonファイルを格納する
      const ref = firebase
        .storage()
        .ref()
        .child("/userBelongClient/" + deleteClientMail.trim() + ".json");
      await ref.getDownloadURL().then((url) => {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.onload = async function (event) {
          var blob = xhr.response;
          var jsonParse = JSON.parse(blob);
          console.log(jsonParse);
          console.log(Object.keys(jsonParse["groups"]).length);
          var belongedNumber = Object.keys(jsonParse["groups"]).length;
          if (belongedNumber == 1) {
            console.log("masterアカウントが削除できる状態になりました");
            //もし残り所属しているクライアントIDが１つならファイル自体を削除する
            var account = firebase
              .storage()
              .ref()
              .child("/userBelongClient/" + deleteClientMail.trim() + ".json");
            await account.delete();

            //マスターアカウントをauthenticationから削除する
            await instance.deleteMasterAccountAuthentication(
              deleteClientMail.trim(),
              deleteClientPass.trim()
            );
          } else {
            console.log(
              "他に管理しているクライアントがいました。カスタムクレームを更新します"
            );
            //もし他に管理しているクライアントがいるのなら、新たにカスタムクレームを更新する
            delete jsonParse["groups"][clientId];

            //このパスにjsonファイルを格納する
            const newBelongedClient = firebase
              .storage()
              .ref()
              .child("/userBelongClient/" + deleteClientMail + ".json");
            const jsonString = JSON.stringify(jsonParse);
            const blob = new Blob([jsonString], { type: "application/json" });
            await newBelongedClient.put(blob).then(() => {
              var addMessage = firebase.functions().httpsCallable("helloWorld");
              addMessage(jsonParse).then(async (result) => {});
            });
          }
        };
        xhr.send();
      });
    },
    deleteMasterAccountAuthentication: async function (
      deleteClientMail,
      deleteClientPass
    ) {
      console.log("-----------deleteMasterAccountAuthentication----------");
      var deleteUser;

      await firebase
        .auth()
        .signOut()
        .then(() => {})
        .catch((error) => {});

      await firebase
        .auth()
        .signInWithEmailAndPassword(deleteClientMail, deleteClientPass)
        .then(
          (user) => {
            // 成功時の処理
            deleteUser = firebase.auth().currentUser;
          },
          (error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
          }
        );
      await deleteUser
        .delete()
        .then(() => {
          firebase
            .auth()
            .signInWithEmailAndPassword(
              this.$store.state.loginEmail,
              this.$store.state.loginPassword
            );
        })
        .catch((error) => {});
    },
    /////////////////////////////////////
    //////////////
    ////////説明
    ////////Authenticationからユーザーを削除する
    ///
    ///引数：なし
    ///////////////////////
    ////////////////////////////////////////////////////
    deleteAuthenticationUser: async function (managerList) {
      console.log("----deleteAuthenticationUser-----");

      //削除するユーザー
      var deleteUser;

      for (var i = 0; i < managerList.length; i++) {
        await firebase
          .auth()
          .signOut()
          .then(() => {})
          .catch((error) => {});

        await firebase
          .auth()
          .signInWithEmailAndPassword(
            managerList[i].mail_address,
            managerList[i].pass
          )
          .then(
            (user) => {
              // 成功時の処理
              deleteUser = firebase.auth().currentUser;
            },
            (error) => {
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
            }
          );
        await deleteUser
          .delete()
          .then(async () => {
            await firebase
              .auth()
              .signInWithEmailAndPassword(
                this.$store.state.loginEmail,
                this.$store.state.loginPassword
              );
          })
          .catch((error) => {});
      }
      console.log("----deleteAuthenticationUserの処理が終了しました-----");
    },
    /////////////////////////////////////
    //////////////
    ////////説明
    ////////公開フォルダを削除する
    ///
    ///引数：なし
    ///////////////////////
    ////////////////////////////////////////////////////
    deletePublishedFolder: async function (clientId) {
      console.log("---------deletePublishedFolder------------");
      //全プロジェクトを取得する
      var allProject = this.$store.state.allProject[0];
      for (var i = 0; i < allProject.length; i++) {
        var userPass = firebase
          .storage()
          .ref()
          .child("/publish/" + clientId + "/" + allProject[i].project_id);

        var list = await userPass.listAll();
        await Promise.all(
          list.items.map(async (element) => {
            await element.delete();
            console.log("deleteしました");
          })
        );
      }
      console.log(
        "---------deletePublishedFolderの処理が終了しました------------"
      );
    },
    deleteServiceLogo: async function (clientId) {
      console.log("---------deleteServiceLogo------------");
      //全プロジェクトを取得する
      var allProject = this.$store.state.allProject[0];
      for (var i = 0; i < allProject.length; i++) {
        var userPass = firebase
          .storage()
          .ref()
          .child("/serviceLogo/" + clientId + "/" + allProject[i].project_id);

        var list = await userPass.listAll();
        await Promise.all(
          list.items.map(async (element) => {
            await element.delete();
            console.log("deleteしました");
          })
        );
      }
      console.log("---------deleteServiceLogoの処理が終了しました------------");
    },
    /////////////////////////////////////
    //////////////
    ////////説明
    ////////vimeoの動画を削除する
    ///
    ///引数：なし
    ///////////////////////
    ////////////////////////////////////////////////////
    deleteVimeo: function () {
      console.log("-----------deleteVimeo①--------------");
      //全プロジェクトを取得する
      var allProject = this.$store.state.allProject[0];
      var folderId;
      for (var i = 0; i < allProject.length; i++) {
        folderId = allProject[i].vimeo_folder_pass;
        client.request(
          {
            method: "DELETE",
            path: folderId + "?should_delete_clips=" + true,
          },
          function (error, body, status_code, headers) {}
        );
      }
      console.log("--------------deleteVimeo----end-----------");
    },
  },
};

//モーダルコンテンツ以外がクリックされた時
addEventListener("click", outsideClose);
function outsideClose(e) {
  if (e.target == document.getElementById("deleteModal")) {
    document.getElementById("deleteModal").style.display = "none";
  }
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap");
.container {
  margin-top: 5%;
}

.period {
  display: inline;
}

.close-box {
  position: relative;
  display: inline-block;
  margin-top: 0px;
  padding-left: 3.4px;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: red;
}

.subject {
  font-size: 32px;
  font-family: "Noto Sans JP", sans-serif;
}

.topic {
  background-color: #f5f5f5;
}

td {
  vertical-align: middle;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
}

th {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: bold;
  font-size: 16px;
}

a {
  color: #ff7500;
}

.new-create-button {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  outline: 0;
  background: transparent linear-gradient(180deg, #e0803e 0%, #d66212 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  width: 110px;
  height: 27px;
  border: 0;
  color: #ffffff;
}
.new-create {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
  margin-right: 2.4%;
}

.delete-button {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  outline: 0;
  background: transparent linear-gradient(180deg, #b0b0b0 0%, #909090 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  width: 56px;
  height: 24px;
  border: 0;
  color: #ffffff;
}

.rewrite-button {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  outline: 0;
  background: transparent linear-gradient(180deg, #e0803e 0%, #d66212 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  width: 56px;
  height: 24px;
  border: 0;
  color: #ffffff;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: #1c1c1c 0% 0% no-repeat padding-box;
  opacity: 1;
}

.modal-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  color: rgb(0, 0, 0);
  margin: 20% auto;
  width: 500px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  animation-name: modalopen;
  animation-duration: 1s;
}

@keyframes modalopen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.delete-text {
  font-family: "Noto Sans JP", sans-serif;
  text-align: center;
  font-size: 24px;
}

.modalClose:hover {
  cursor: pointer;
}

.modal-body {
  width: 500px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.text-content {
  margin-top: 10%;
  padding: 0%;
}

.modal-reserve-button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: linear-gradient(#20b2aa, 5%, #5f9ea0);
  width: 80px;
  height: 30px;
  border: 0;
  color: #ffffff;
}

.modal-cancel-button {
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: transparent linear-gradient(180deg, #b1b1b1 0%, #8e8e8e 100%) 0%
    0% no-repeat padding-box;
  border: 0.5px solid #a5a5a5;
  opacity: 1;
  width: 100px;
  height: 40px;
  border: 0;
  color: #ffffff;
}

.btn-modal {
  text-align: center;
  margin-bottom: 7%;
  margin-top: 5%;
}

.btn-modal > div {
  margin-left: 5%;
  margin-right: 5%;
  display: inline-block;
}
</style>

<template>
  <div class="client-home">
    <Bar />
    <ClientHome />
    <div id="fadeLayer"></div>
    <div class="loading">
      <vue-loading
        id="loading"
        type="spin"
        color="#333"
        :size="{ width: '50px', height: '50px' }"
      ></vue-loading>
    </div>
  </div>
</template>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script type="module">
import ClientHome from "@/components/Client/ClientHome.vue";
import Bar from "@/components/Bar/BarComponent.vue";
import { VueLoading } from "vue-loading-template";
import AuthStateCheckMixin from "../../utility/authStateCheck";
export default {
  name: "Client",
  components: {
    ClientHome,
    Bar,
    VueLoading,
  },
  mounted() {
    this.authStateCheck(this.$store, this.$router);
    this.waitLoading();
    this.getClientListJsonFile();
  },
  data: function () {
    return {
      temporaryData: [],
    };
  },
  mixins: [AuthStateCheckMixin],
  methods: {
    getClientInfo: async function () {
      //onload内ではthisで値を取得出来ないので、この変数にthisを格納している
      var instance = this;

      //クライアントの表示順を変更する際、全てのクライアントを格納したかどうかの値
      var clientListCount = 0;

      //onload内ではthisで値を取得出来ないので、この変数にthis.$storeを格納している
      var store = this.$store;

      //このクライアントIDをもとにクライアント情報を取得する
      var clientIdList = this.$store.state.clientIdList;

      for (var i = 0; i < clientIdList.length; i++) {
        firebase
          .storage()
          .ref()
          .child(
            "/client/" +
              clientIdList[i].id +
              "/" +
              clientIdList[i].seacret +
              "/" +
              clientIdList[i].id +
              ".json"
          )
          .getDownloadURL()
          .then((url) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.onload = function (event) {
              var blob = xhr.response;
              instance.temporaryData.push(JSON.parse(blob));
              store.commit("setClientInfo", JSON.parse(blob));
              clientListCount += 1;

              if (clientListCount == clientIdList.length) {
                console.log("クライアントの総数は"+clientListCount+"です。");
                //クライアントを登録順に並び替える
                store.commit("changeClientInfo", JSON.parse(blob));

                instance.getClientDB();

                //ローディング表示を消す
                instance.downloaded();
              }
            };
            xhr.send();
          });
      }

      if (clientIdList.length == 0) {
        instance.getClientDB();

        //ローディング表示を消す
        this.downloaded();
      }
    },
    /**
     * クライアントのIDリストを取得
     */
    getClientListJsonFile: async function () {
      console.log("----------getClientListJsonFileが呼ばれました-------------")
      //クライアントをリセットする。でないと同じデータが溜まり続ける。
      this.initializeData();

      var instance = this;
      firebase
        .storage()
        .ref()
        .child("/adminClientList/clientIdList.json")
        .getDownloadURL()
        .then((url) => {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url);
          xhr.onload = function (event) {
            var blob = xhr.response;
            var adminInfo = JSON.parse(blob);
            instance.$store.commit("setClientIdList", adminInfo);

            //取得したクライアントIDを元にクライアントの情報を取得する
            instance.getClientInfo();
          };
          xhr.send();
        });
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //クライアントの使用量DBを取得する
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    getClientDB: async function () {
      var instance = this;
      var store = this.$store;
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var listRef = storageRef.child("/db/");
      var list = await listRef.listAll();
      var clientDB = [];
      var clientDBList = [];
      var count = 0;
      Promise.all(
        list.items.map(async (element) => {
          await clientDB.push(element);
        })
      );
      if (clientDB.length != 0) {
        for (var i = 0; i < clientDB.length; i++) {
          clientDB[i]
            .getDownloadURL()
            .then((url) => {
              var xhr = new XMLHttpRequest();
              xhr.open("GET", url);
              xhr.onload = function (event) {
                var blob = xhr.response;
                clientDBList.push(JSON.parse(blob));
                count += 1;
                if (clientDB.length == count) {
                  console.log("countの値は"+count+"です");
                 
                 //クライアントのDBと使用本数を設定する
                  store.commit("changeDBAndNumber", clientDBList);
                 
                  //ローディング表示を消す
                  instance.downloaded();
                }
              };
              xhr.send();
            })
            .then((response) => {
              // 返ってきたresponseのdataプロパティにjsonファイルの中身が格納されている
              const data = response;
            });
        }
      } else {
        //ローディング表示を消す
        instance.downloaded();
      }
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを初期化する
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    initializeData: function () {
      console.log("--------initializeDataが呼ばれました------------")
      //クライアントをリセットする。でないと同じデータが溜まり続ける。
      this.$store.commit("resetClientInfo");
      this.temporaryData = [];
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得している間の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    waitLoading: function () {
      document.getElementById("loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得した後の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    downloaded: function () {
      document.getElementById("loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
  },
};
</script>
<style scoped>
#fadeLayer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  z-index: 999999999;
}

#loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 80%;
  height: 3.2rem;
}
</style>
<template>
  <ul class="topnav">
    <div class="navigation">
      <li class="client-name">マインドフリーadmin</li>
      <div class="data">
        <li class="right">
          <button class="logout" v-on:click="logout()">ログアウト</button>
        </li>
      </div>
    </div>
  </ul>
</template>
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-auth.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script>
export default {
  name: "ProjectBarComponent",
  data: function () {
    return {};
  },
  mounted() {},
  methods: {
    ///////////////////////////////
    ////説明
    ////ログアウト
    ////
    ////
    ////////
    /////////////
    /////////////////////////////////////////
    logout: function () {
      this.$store.commit("resetUser");
      firebase.auth().onAuthStateChanged((user) => {
        firebase
          .auth()
          .signOut()
          .then(() => {
            //this.$router.back();
            this.$router.go({path: this.$router.currentRoute.path, force: true})//新しく追加
          })
          .catch((error) => {
          });
      });
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
ul.topnav {
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background: #3f3d56 0% 0% no-repeat padding-box;
  height: 54px;
}
ul.topnav li {
  float: left;
  font-family: "Noto Sans JP", sans-serif;
}

ul.topnav li.right {
  float: right;
}

.used-content {
  position: relative;
  background: #de7b37 0% 0% no-repeat padding-box;
  height: 20px;
  top: 10px;
  border-radius: 2px;
  display: block;
  padding: 14px 16px;
  text-align: center;
  text-decoration: none;
  color: white;
  font-size: 12px;
}

p {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
  position: relative;
  top: -10px;
}

.value {
  position: relative;
  height: 20px;
  top: 12px;
  display: block;
  padding: 14px 16px;
  text-align: center;
  text-decoration: none;
  color: white;
}

.client-name {
  margin-left: -40px;
  font-size: 16px;
  color: white;
  display: block;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  color: white;
}

.navigation {
  margin-top: 0.3%;
  margin-left: 9%;
}

.data {
  float: right;
}

.logout {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
  width: 92px;
  height: 65px;
  background: #a7a7a7 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: -7px;
  margin-right: -2px;
  color: white;
}
</style>
